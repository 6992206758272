exports.onPreRouteUpdate = ({ location, prevLocation }) => {
    if( prevLocation && location.pathname !== prevLocation.pathname) document.querySelector('html').classList.remove('smooth');
}
exports.onRouteUpdate = () => {

    function navInit() {

        const splittedPathname = window.location.pathname.split('/');

        let isBlog = splittedPathname[1] === "blog" && splittedPathname.length == 3;

        let $body = document.querySelectorAll('body')[0];
        let $nav = document.getElementsByClassName('js-nav')[0];
        let $announcementBar = document.getElementById('announcement-bar');
        let $btn = document.getElementsByClassName('js-toggleNav')[0];
        let navTimer = null;
        var hideNavItems = document.querySelectorAll('.js-hideNav');

        // Show nav
        function showNav() {
            clearTimeout(navTimer);
            navTimer = null;
            $body.classList.remove('nav-closing');

            $nav.style.display = 'block';
            $body.classList.add('nav-opening');
            $body.classList.add('nav-visible');

            if (!isBlog) {
                $announcementBar.style.display = 'none';
            }
        } // showNav

        // Hide nav
        function hideNav() {

            $body.classList.remove('nav-visible');
            $body.classList.remove('nav-opening');
            $body.classList.add('nav-closing');

            navTimer = setTimeout(function () {
                // $header.classList.remove('nav-closing');
                $nav.style.display = 'none';
            }, 600);

            if (!isBlog) {
                $announcementBar.style.display = 'inline-block';
            }
        } // showNav

        // Toggle Click
        if($btn){
            $btn.addEventListener('click', function () {
                // $header.classList.contains('opening-nav') ? hideNav() : showNav();
                $body.classList.contains('nav-visible') ? hideNav() : showNav();
                return false;
            })
        }

        // Hide nav

        hideNavItems.forEach(($link) => {
            $link.addEventListener('click', (e) => {
                hideNav();
            })
        })

    } // navInit



    // DISABLE FOCUS

    function generalEvents() {

        const els = document.querySelectorAll('a, button, input[type="checkbox"]');
        els.forEach(($el) => {
            $el.addEventListener('click', () => {
                $el.blur();
            })
        })

    } // generalEvents


    function filtersInit() {

        const $parent = document.querySelector('.js-filter');

        if ($parent) {
            const filterBtns = document.querySelectorAll('.js-filter__btn');
            const filterItems = document.querySelectorAll('.js-filter__item');
            let firstRenderAfterClick = true;
            var filterTimeout = null;

            filterBtns.forEach(($filter) => {

                $filter.addEventListener('click', (e) => {

                    if (!$filter.classList.contains('is-active')) {

                        clearTimeout(filterTimeout);
                        filterTimeout = null;

                        if (firstRenderAfterClick) {
                            $parent.classList.add('is-animated');
                            firstRenderAfterClick = false;
                        }

                        let filterVal = $filter.getAttribute('data-filter');

                        // Set active to filter
                        filterBtns.forEach(($f) => {
                            $f.classList.remove('is-active');
                            $f.removeAttribute('tabindex');
                        })
                        $filter.classList.add('is-active');
                        $filter.setAttribute('tabindex', '-1');

                        // Toggle items

                        toggleItems(filterVal);

                    } // if !active

                });

            });

            function toggleItems(filterVal) {

                let timeout = 700;
                if ($parent.getAttribute('data-filter-timeout')) {
                    timeout = parseInt($parent.getAttribute('data-filter-timeout'));
                }

                filterItems.forEach(($item) => {
                    $item.classList.remove('is-active', 'is-animated');
                });

                let activeItems = null;

                if (filterVal === 'all') {
                    activeItems = filterItems;
                } else {
                    activeItems = document.querySelectorAll('.js-filter__item[data-filter*="' + filterVal + '"]');
                }
                activeItems.forEach(($active) => {
                    $active.classList.add('is-active', 'is-animated');
                    filterTimeout = setTimeout(() => {
                        $active.classList.remove('is-animated');
                    }, timeout)
                })

            } // toggleItems


            // SWITCH SERVICE VIA BTN

            const serviceFilterBtns = document.querySelectorAll('.js-service-filter');
            if (serviceFilterBtns.length > 0) {

                const headerHolderHeight = document.querySelector('.c-header__holder').offsetHeight;

                serviceFilterBtns.forEach(($btn) => {
                    $btn.addEventListener('click', () => {
                        let filterVal = $btn.getAttribute('data-filter');

                        let $filtersNav = document.getElementById('filter-nav');
                        let offset = $filtersNav.offsetTop - (headerHolderHeight * 2)

                        window.scrollTo(0, offset)

                        switchFilter(filterVal);
                    })
                })

            } // if serviceFilterBtns > 0

            function switchFilter(filterVal) {

                // Set active to filter
                filterBtns.forEach(($f) => {
                    if (filterVal.indexOf($f.getAttribute('data-filter')) === -1) {
                        $f.classList.remove('is-active');
                        $f.removeAttribute('tabindex');
                    } else {
                        $f.classList.add('is-active');
                        $f.setAttribute('tabindex', '-1');
                    }
                })

                // Toggle items
                toggleItems(filterVal);

            } // switchFilter


            // SWITCH SERVICE VIA PARAM

            const url = window.location.href;
            if (url.indexOf('filter=') !== -1) {
                let filterVal = url.split('filter=')[1];
                switchFilter(filterVal);
            }


            // FILTER ON RENDER

            if ($parent.classList.contains('js-filter__onInit')) {

                let $activeFilterBtn = document.querySelector('.js-filter__btn.is-active');
                let filterVal = $activeFilterBtn.getAttribute('data-filter');

                switchFilter(filterVal);
            }

        }

    } // filtersInit






    /* ***** TRIGGERS ***** */

    filtersInit();

    generalEvents();
    navInit();

}
